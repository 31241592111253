import React, { useEffect, useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CircularProgressWithLabel from "../treatment-plan-form/CircularProgressWithLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import moment from "moment";
import { TableSortLabel } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { API } from "../../constants";
import Alert from "@material-ui/lab/Alert";
import ShareDialog from "../Usage/ShareDialog";
import { getMpsUser } from "../../graphql/queries";
import pass from "../../images/Pass.png";
import fail from "../../images/Fail.png";
import pdf from "../../images/pdf_black-removebg-preview.png";
import processing from "../../images/Processing.png";
import WarningIcon from "../../images/WarningIcon.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";

const arr4 = [];
const selectedDownloadableCase2 = [];
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    width: "100%",
  },
  createButton1: {
    background: "#FFB500",
    boxShadow: "none",
    color: "#000",
    fontWeight: "bold",
    fontSize: "12px",
    fontFamily: "Helvetica",

    "&:hover": {
      background: "#FFD271",
      boxShadow: "none",
      color: "#000",
    },
    "&:focus": {
      background: "#FFB500!important",
      boxShadow: "none",
      color: "#000",
    },
  },
  container: {
    maxHeight: 550,
    border: "1px solid lightgrey",
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function PatientScacsTable(props) {
  const classes = useStyles2();
  const { cases } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [selectedDownloadZipFileScanID, setSelectedDownloadZipFileScanID] =
    React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [downloadZipFileProgressBarValue, setDownloadZipFileProgressBarValue] =
    React.useState(0);
  const [shareDialog, setShareDialog] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [selectedPatientId, setSelectedPatientId] = React.useState("");
  const [mpsUserData, setMpsUserData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [nurl, setNurl] = useState("");
  const [alertPdfOpen, setAlertPdfOpen] = React.useState(false);

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      props.scans &&
        props.scans.content &&
        props.scans.content.length - page * rowsPerPage
    );

  useEffect(() => {
    setPage(0);
  }, [props.scans]);

  useEffect(() => {
    if (true) {
      setLoader(true);
      getMpsUser().then((res) => {
        let t1 = res.get_mps_user;
        let t = [];
        for (let i = 0; i < t1.length; i++) {
          t.push({
            // "value":t1[i].email ,
            "label": t1[i].federationidentifier
          })
        }
        setMpsUserData(t);
        // setFacilities(res.get_mps_user);
        setLoader(false);
      })
    }
  }, ["value"]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShare = (metaData) => {
    window.localStorage.setItem("isThisInLocalStorage4", "true");
    window.dispatchEvent(new Event("storage4"));
    setSelectedPatientId(metaData.metadata.patientScanId)
    setShareDialog(true);
    setLoader(true);
    setLoader(false);
  };

  const patientScanViewerMetadata = async (scanId) => {
    const patientscan_id = scanId;
    const { mako_token, as1_token } = JSON.parse(
      localStorage.getItem("PU:TOKEN") || "{}"
    );
    const config = {
      method: "GET",
      url: API.MIB.PATIENT_SCAN_URI + patientscan_id + "/viewermetadata",
      data: {},
      headers: {
        Authorization:
          cases === "MAKO" ? "Bearer " + mako_token : "Bearer " + as1_token,
        "Content-Type": "application/json",
      },
    };
    let resp = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        setAlertOpen(true);
        setTimeout(function () {
          setAlertOpen(false);
        }, 10000);

        setAlertMessage(`patientScanViewerMetadata function error: ${error}`);
        setAlertType("error");
      });
    return resp;
  };

  const patientScanViewer = async (scanId) => {
    const patientscan_id = scanId;
    const { mako_token, as1_token } = JSON.parse(
      localStorage.getItem("PU:TOKEN") || "{}"
    );

    const config = {
      method: "GET",
      url: API.MIB.PATIENT_SCAN_URI + patientscan_id + "/viewer",
      data: {},
      headers: {
        Authorization:
          cases === "MAKO" ? "Bearer " + mako_token : "Bearer " + as1_token,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        setAlertOpen(true);
        setTimeout(function () {
          setAlertOpen(false);
        }, 10000);

        setAlertMessage(`patientScanViewer function error: ${error}`);
        setAlertType("error");
      });
  };

  const handlViewScanFile = async (e, scanId) => {
    const result = await patientScanViewerMetadata(scanId);
    if (
      (result && result.provisioningStatus === "InProgress") ||
      (result && result.provisioningStatus === "Failed") ||
      (result && result.provisioningStatus === "Unprovisioned")
    ) {
      setAlertOpen(true);
      setTimeout(function () {
        setAlertMessage("");
        setAlertOpen(false);
      }, 1000);

      setAlertMessage("Please wait, Patient Scan is being provisioned.");
      setAlertType("error");

      let retryCount = 0;
      let intervalFunc = setInterval(async () => {
        retryCount++;
        if (result.provisioningStatus !== "Completed" && retryCount < 2) {
          let resp = await patientScanViewer(scanId)
            .then(async (response) => {
              await patientScanViewerMetadata(scanId);
              if (result.provisioningStatus === "Completed") {
                // setViewScanButtonDisabled("")
                clearInterval(intervalFunc);
              }
            })
            .catch((error) => {
              setAlertOpen(true);
              setTimeout(function () {
                setAlertMessage("");
                setAlertOpen(false);
              }, 10000);

              setAlertMessage(`handlViewScanFile function error: ${error}`);
              setAlertType("error");
            });
        } else {
          clearInterval(intervalFunc);
        }
      }, 30000);
    } else {
      const url = result.viewerUrl;
      window.open(url, "_blank");
    }
  };

  function handleDownloadZipFile2 (scanIds) {
    let selectedCaseIndex1 = selectedDownloadableCase2.findIndex(i => i.id === scanIds);
    return selectedDownloadableCase2[selectedCaseIndex1].value;
  }

  const handleDownloadZipFile = (e, scanId, fileName) => {
    selectedDownloadableCase2.push({ id :scanId, value : 0});
    arr4.push(scanId);
    const { mako_token, as1_token } = JSON.parse(
      localStorage.getItem("PU:TOKEN") || "{}"
    );
    const pateientscanFileName = fileName;
    const patientscan_id = scanId;
    setSelectedDownloadZipFileScanID(patientscan_id);
    const config = {
      method: "GET",
      url: API.MIB.PATIENT_SCAN_URI + patientscan_id,
      data: {},
      headers: {
        Authorization:
          cases === "MAKO" ? "Bearer " + mako_token : "Bearer " + as1_token,
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentCompleted = Math.floor((loaded * 100) / total);
        if (percentCompleted < 100) {
          let selectedCaseIndex1 = selectedDownloadableCase2.findIndex(i => i.id === scanId);
          selectedDownloadableCase2[selectedCaseIndex1].value = percentCompleted;
          setDownloadZipFileProgressBarValue(percentCompleted);
        }
      },
    };

    axios(config)
      .then((response) => {
        setDownloadZipFileProgressBarValue(100);
        setTimeout(() => {
          setDownloadZipFileProgressBarValue(0);
        }, 1000);
        let index1 = arr4.indexOf(scanId);
        let selectedCaseIndex = selectedDownloadableCase2.findIndex(i => i.id === scanId);
        let filename = pateientscanFileName
          ? pateientscanFileName.includes(".")
            ? pateientscanFileName
            : pateientscanFileName + ".zip"
          : patientscan_id + ".zip";
        fileDownload(response.data, filename);
        selectedDownloadableCase2.splice(selectedCaseIndex, 1);
        arr4.splice(index1, 1);
      })
      .catch((error) => {
        setAlertOpen(true);
        setTimeout(function () {
          setAlertOpen(false);
        }, 10000);

        setAlertMessage("Patient scan cannot be downloaded");
        setAlertType("error");
      });
  };
  const handleViewScan = (e, pdfReportDipsItemId) => {
    if (!(pdfReportDipsItemId?.length>0)){
      setAlertPdfOpen(true);
      setAlertMessage(
        "Associated Item Data Not Available!" 
        );
        setAlertType("error");
      
      return
    }
    const { dips_token } = JSON.parse(localStorage.getItem("PU:TOKEN") || "{}");
    setLoader(true)
    const config = {
      method: "GET",
      url: `${API.PATIENT_SCANS_PDF}/${pdfReportDipsItemId}/download`,
      data: {},
      headers: {
        Authorization: "Bearer " + dips_token,
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    };
    axios(config)
      .then((response) => {
        setLoader(false)

        let file = new Blob([response.data], { type: "application/pdf" });
        let fileURL = URL.createObjectURL(file);
        setNurl(fileURL);
        setOpen(true);
      })
      .catch((error) => {
        setAlertOpen(true);
        setLoader(false)
        setTimeout(function () {
          setAlertOpen(false);
        }, 10000);

        setAlertMessage("Patient scan is in process");
        setAlertType("error");
      });
  };

  const handleAlertClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
    setAlertPdfOpen(false);

  };

  return (
    <div> 
       { loader &&  <CircularProgress style={{ marginLeft: "50%", color: "gold" }} />}
       {alertPdfOpen ? (
        <div style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr", zIndex:"9999999999" }}>
          <div></div>
          <Alert
            onClose={(e) => handleAlertClose(e)}
            severity={alertType}
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "0px",
              paddingBottom: "2px",
            }}
          >
            {alertMessage}
          </Alert>
        </div>
      ) : null}
      {alertOpen ? (
        <div style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr" }}>
          <div></div>
          <Alert
            onClose={(e) => handleAlertClose(e)}
            severity={alertType}
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "0px",
              paddingBottom: "2px",
            }}
          >
            {alertMessage}
          </Alert>
        </div>
      ) : null}
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer
            className={classes.container}
            component={Paper}
            style={{ position: "sticky" }}
          >
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <TableSortLabel
                      active={props.sortBy === "scanType"}
                      direction={props.sortOrder}
                      onClick={props.requestSort("scanType")}
                    >
                      Scan Type
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={props.sortBy === "name"}
                      direction={props.sortOrder}
                      onClick={props.requestSort("name")}
                    >
                      Patient Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={props.sortBy === "DOB"}
                      direction={props.sortOrder}
                      onClick={props.requestSort("DOB")}
                    >
                      Date of Birth
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={props.sortBy === "uploadby"}
                      direction={props.sortOrder}
                      onClick={props.requestSort("uploadby")}
                    >
                      Uploaded By
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={props.sortBy === "slices"}
                      direction={props.sortOrder}
                      onClick={props.requestSort("slices")}
                    >
                      Number of Slices
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={props.sortBy === "date"}
                      direction={props.sortOrder}
                      onClick={props.requestSort("date")}
                    >
                      Modification Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={props.sortBy === "physician"}
                      direction={props.sortOrder}
                      onClick={props.requestSort("physician")}
                    >
                      Referring Physician
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">View Scan</TableCell>
                  <TableCell align="center">Download</TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={props.sortBy === "scanCheck"}
                      direction={props.sortOrder}
                      onClick={props.requestSort("scanCheck")}
                    >
                      MIC Report
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Share</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto", height: "150px" }}>
                {(rowsPerPage > 0
                  ? props.scans &&
                    props.scans.content &&
                    props.scans.content.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : props.scans && props.scans.content
                ).map((row) => (
                  <TableRow key={row.name} hover>
                    <TableCell align="left">
                      {row.metadata
                      ? row.metadata.studyDescription : "--"}
                    </TableCell>
                    <TableCell align="left" style={{'word-wrap': 'break-word','width': '140px','white-space': 'normal'}}>
                      {row.metadata && row?.patient?.patientName
                        ? row.patient.patientName
                        : "--"}
                    </TableCell>
                    <TableCell align="left">
                      {row.metadata && row?.patient?.patientDateOfBirth
                        ? moment(row.patient.patientDateOfBirth).format(
                            "MM/DD/YYYY"
                          )
                        : "--"}
                    </TableCell>
                    <TableCell align="left">
                      {(row.metadata && row.metadata.uploadedByUser) ||
                        row.metadata.facilityName ||
                        row.metadata.uploadedByScanFacility ||
                        row.metadata.uploadedByApplication ||
                        "--"}
                    </TableCell>

                    <TableCell align="center">
                      {row.metadata && row.metadata.totalNumberOfImages
                        ? row.metadata.totalNumberOfImages
                        : "--"}
                    </TableCell>
                    <TableCell align="left">
                      {row.metadata && row.metadata.dateTimeLastModified
                        ? moment(row.metadata.dateTimeLastModified).format(
                            "MM/DD/YYYY"
                          )
                        : "--"}
                    </TableCell>
                    <TableCell align="left">
                      {row.metadata && row.metadata.referringPhysicianName
                        ? row.metadata.referringPhysicianName.replace(
                            /\^/g,
                            " "
                          )
                        : "--"}
                    </TableCell>
                    <TableCell size="small" align="center">
                      <Button
                        aria-label="View"
                        onClick={(e) =>
                          handlViewScanFile(e, row.metadata.patientScanId)
                        }
                        variant="outlined"
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell size="small" align="center">
                      {downloadZipFileProgressBarValue > 0 &&
                      arr4.includes(row.metadata.patientScanId) ? (
                        <CircularProgressWithLabel
                          value={ handleDownloadZipFile2(row.metadata.patientScanId)}
                        />
                      ) : (
                        <IconButton
                          aria-label="download"
                          onClick={(e) =>
                            handleDownloadZipFile(
                              e,
                              row.metadata.patientScanId,
                              row.metadata.patientScanDescriptor
                            )
                          }
                        >
                          <ArrowDownwardIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {(() => {
                        const pdfReportDipsItemId =
                          row?.additionalData?.jobs?.mic?.pdfReportDipsItemId;
                        const resultSummary =
                          row?.additionalData?.jobs?.mic?.resultSummary;
                        const treatmentSummary =
                          row?.additionalData?.jobs?.mic?.treatmentsSummary;

                        if (resultSummary === "pass") {
                          return (
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center",marginLeft:'-14px'}}>
                            <IconButton
                              aria-label="download"
                              onClick={(e) =>
                                handleViewScan(e, pdfReportDipsItemId)
                              }
                            >
                              <img
                                width="40px"
                                height="40px"
                                // src={pass}
                                // alt="pass"
                                src={pdf}
                                alt="pdf"
                              />
                            </IconButton>
                            <div style={{width:'40px',height:'40px'}}></div>
                          </div>
                          );
                        } else if (resultSummary === "fail") {
                          const verifyMotion = "Please ensure motion check passes on your planning laptop prior to creating the TPR";

                          const hasMotionDetected = treatmentSummary.every(
                            (treatment) =>
                              treatment.failureExplanations.includes(
                                verifyMotion
                              )
                          );

                          if (hasMotionDetected) {
                            return (
                              <div style={{display: "flex", justifyContent: "center", alignItems: "center",marginLeft:'-14px'}}>
                              <IconButton
                                aria-label="download"
                                onClick={(e) =>
                                  handleViewScan(e, pdfReportDipsItemId)
                                }
                              >
                                <img
                                width="40px"
                                height="40px"
                                // src={WarningIcon}
                                // alt="WarningIcon"
                                src={pdf}
                                alt="pdf"
                              />
                              </IconButton>
                              <img
                            width="40px"
                            height="40px"
                            src={WarningIcon}
                            alt="WarningIcon"
                          />
                              </div>
                            );
                          } else {
                            return (
                              <div style={{display: "flex", justifyContent: "center", alignItems: "center",marginLeft:'-14px'}}>
                              <IconButton
                                aria-label="download"
                                onClick={(e) =>
                                  handleViewScan(e, pdfReportDipsItemId)
                                }
                              >
                                   <img
                                width="40px"
                                height="40px"
                                // src={fail}
                                // alt="Fail"
                                src={pdf}
                                alt="pdf"
                              />
                              </IconButton>
                              <img
                            width="40px"
                            height="40px"
                            src={WarningIcon}
                            alt="Fail"
                          />
                            </div>
                            )
                          }
                        } 
                        else {
                          return (
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center",marginLeft:'-14px'}}>
                             
                            <IconButton
                              aria-label="download"
                              style={{'pointerEvents':'none'}}
                            >
                              <img
                                width="50px"
                                height="50px"
                                src={processing}
                                alt="In Process"
                              />
                            </IconButton>
                            <div style={{width:'40px',height:'40px'}}></div>
                            </div>
                           
                          );
                        }
                      })()}
                    </TableCell>
                    <TableCell size="small" align="center">
                      <Button
                        aria-label="View"
                        Icon="EMAIL"
                        disabled={false}
                        onClick={() => handleShare(row)}
                        name="EMAIL"
                        variant="outlined"
                        className={classes.createButton1}
                      >
                        Share
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ShareDialog
            //  style={ {maxWidth: "750px"}}
            open={shareDialog}
            datas={mpsUserData}
            cases={props.cases}
            selectedPatientId={selectedPatientId}
            onClose={() => setShareDialog(false)}
            // onClick={ () => handleShare(row.metadata)}
            displaySnackBarMessage={props.displaySnackBarMessage}
          />
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
            <div></div>
            <div></div>
            <div>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
                count={
                  props.scans &&
                  props.scans.content &&
                  props.scans.content.length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </div>
          </div>
        </Paper>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth="xl"
          fullWidth
        >
          <div style={{width:'70px',marginLeft:'96%',marginBottom:'-12px'}}> <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton></div>
         
          <DialogContent>
            <iframe
              id="patientscanpdf"
              title="pdf"
              src={nurl}
              width="100%"
              height="1000"
              frameborder="0"
              type="application/pdf"
            />

            <Button
              type="submit"
              onClick={() => setOpen(false)}
              variant="contained"
              style={{
                backgroundColor: "#ffb500",
                color: "black",
                fontWeight: "bold",
                display: "block",
                margin: "0 auto",
                marginTop: "60px",
                marginBottom: "10px",
              }}
            >
              Dismiss
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
